* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  transition: background-color 0.2s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  overflow: hidden;
  --dark-theme-secondary: #2f2e2e;
  --light-theme-secondary: #dfdada;
  --dark-theme-third: #3c3b3b;
  --light-theme-third: #e8e0e0;
}

.dark-theme {
  background-color: #1e1e1e;
  color: #d4d4d4;
}

.light-theme {
  background-color: #f5f5f5;
  color: #1e1e1e;
}

::-webkit-scrollbar {
  display: none;
}

/* Language: css */
